import header from './header.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={header} className="App-header" alt="header" />
        <p>Black Ice Tracker coming soon...</p>
        <p className="App-tiny">fuck wonkywillard68.</p>
      </header>
    </div>
  );
}

export default App;
